import React, {Fragment} from 'react';
import ButtonSelect from "../../../Components/Form/ButtonSelect";
import AvailabilityContext from "./Context";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cx from "classnames";

const AvailabilityDate = ({onNext, onBack}) => {
    const {profile, setProfile, errors} = React.useContext(AvailabilityContext);

    const changeWeekDay = (key, type, value) => {
        const newProfile = {...profile};
        newProfile.weekDays[key][type] = value;
        setProfile(newProfile);
    };

    const changeSingleDay = (type, date) => {
        const newProfile = {...profile};
        newProfile.singleDay[type] = date;
        setProfile(newProfile);
    };

    return (<form onSubmit={e => e.preventDefault()}>
        <h1 className="subtitle">Availability Date</h1>

        <div style={{display: 'flex'}}>
            <div>How often would you like to play</div>
            <ButtonSelect
                value={profile.recurrence}
                options={[{value: 'day', label: 'Specific Day'}, {value: 'rec', label: 'Week Selection'}]}
                setValue={recurrence => setProfile({...profile, ...{recurrence}})}/>
        </div>
        {errors['recurrences'] && <div className="help is-danger">{errors['recurrences'][0]}</div>}

        {profile.recurrence === "day" && <div>
            <label className="label">Date</label>
            <DatePicker
                selected={profile.singleDay.day}
                onChange={date => changeSingleDay('day', date)}
                dateFormat="dd-MM-yyyy"
                className="input"
            />
            {errors['availability.day'] && <div className="help is-danger">{errors['availability.day'][0]}</div>}

            <label className="label">Start Time</label>
            <DatePicker
                selected={profile.singleDay.start}
                onChange={date => changeSingleDay('start', date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                dateFormat="h:mm aa"
                className="input"
            />
            {errors['availability.start'] && <div className="help is-danger">{errors['availability.start'][0]}</div>}

            <label className="label">End Time</label>
            <DatePicker
                selected={profile.singleDay.end}
                onChange={date => changeSingleDay('end', date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                dateFormat="h:mm aa"
                className="input"
            />
            {errors['availability.end'] && <div className="help is-danger">{errors['availability.end'][0]}</div>}
        </div>}

        {profile.recurrence === 'rec' &&
        <div>
            <div>Click on the weekday on which you are available:</div>
            {profile.weekDays.map(({day, start, end, active}, index) =>
                <div key={index}>
                    <button className={cx("button", {'is-primary': active})} onClick={() => changeWeekDay(index, 'active', !active)}>
                        {day}
                    </button>
                    {active && <Fragment>
                        <DatePicker
                            selected={start}
                            onChange={date => changeWeekDay(index, 'start', date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            dateFormat="h:mm aa"
                            className="input"/>
                        {errors['recurrences.' + index + '.start'] && <div className="help is-danger">{errors['recurrences.' + index + '.start'][0]}</div>}
                        <DatePicker
                            selected={end}
                            onChange={date => changeWeekDay(index, 'end', date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            dateFormat="h:mm aa"
                            className="input"/>
                        {errors['recurrences.' + index + '.end'] && <div className="help is-danger">{errors['recurrences.' + index + '.end'][0]}</div>}
                    </Fragment>}
                </div>)
            }
        </div>
        }

        <button className="button is-primary" onClick={() => onNext()}>Next</button>
        <button className="button is-text" onClick={() => onBack()}>Back</button>
    </form>);
};

export default AvailabilityDate;