import React from 'react';
import cx from 'classnames';

const ButtonSelect = ({value, setValue, options, errors = null, label = null}) =>
    <div className="field">
        {label && (<label className="label">{label}</label>)}
        <div className="buttons">
            {options.map((option, index) =>
                <button
                    className={cx("button", {'is-primary': option.value === value})}
                    key={index} onClick={() => setValue(option.value)}>
                    {option.label}
                </button>
            )}
        </div>
        {errors && <div className="help is-danger">{errors[0]}</div>}
    </div>;


export default ButtonSelect;