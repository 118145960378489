import React, {useState} from 'react';
import Autocomplete from './Assets/Autocomplete';
import {searchAddress, createAddress} from '../../Helpers/Api/Address';

const AddressSearch = ({entity, setEntity, errors, setErrors, label = null, save = true}) => {
    const [term, setTerm] = useState('');
    const [options, setOptions] = useState([]);
    const formatAddress = address => `${address.street} ${address.suburb}, ${address.state}`;

    const getOptions = (searchString) => {
        setEntity({});
        if (searchString) {
            searchAddress(searchString)
                .then(({hits}) => {
                    let locations = hits.map(hit => ({
                        street: hit.locale_names[0],
                        state: hit.administrative ? hit.administrative[0] : '',
                        suburb: hit.suburb ? hit.suburb[0] : (hit.city ? hit.city[0] : ''),
                        postcode: hit.postcode ? hit.postcode[0] : '',
                        country: hit.country_code,
                        lat: hit._geoloc.lat,
                        lng: hit._geoloc.lng
                    }));
                    setOptions(locations.map((address, index) => ({value: index, label: formatAddress(address, index), address: address})));
                });
        } else
            setOptions([]);
    };

    const setAddressDetails = (address) => {
        if(save) {
            createAddress({address})
                .then((data) => {
                    setEntity(data);
                    setTerm(formatAddress(data));
                })
                .catch(e => {
                    typeof e === 'object' && setErrors(e);
                    setEntity({});
                });
        } else {
            setEntity(address);
            setTerm(formatAddress(address));
        }
    };

    const selectOption = (option) => {
        if(errors)
            setErrors([]);
        setOptions([]);
        setAddressDetails(option.address);
    };

    if (!term && entity?.id)
        entity.street ? setTerm(formatAddress(entity)) : setAddressDetails({id: entity.id});

    return <Autocomplete
        label={label}
        name="address"
        placeholder="Address"
        value={term}
        setValue={setTerm}
        options={options}
        searchCallback={getOptions}
        selectCallback={selectOption}
        errors={errors}
        delay={200}/>;
};

export default AddressSearch;