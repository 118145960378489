import React from 'react';

const TextField = ({value = '', onChange, name, errors = null, label = null, placeholder = '', type = 'text'}) => {
    return (
        <div className="field">
            { label && (<label className="label">{label}</label>) }
            <div className="control">
                <input className="input" name={name} type={type} placeholder={placeholder} value={value} onChange={e => onChange(e.target.value)}/>
            </div>
            {errors && <div className="help is-danger">{errors[0]}</div>}
        </div>);
};

export default TextField;