import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Auth from "../../Helpers/Authentication";

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={(props) =>
            Auth.isAuthenticated ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/restricted",
                        state: {from: props.location}
                    }}
                />
            )
        }
    />
);

export default PrivateRoute;