import React, {Fragment, useState} from 'react';
import AddressSearch from "../../../Autocomplete/AddressSearch";
import {listPublicLocation} from "../../../../Helpers/Api/Location";
import styles from './MyLocation.module.scss';
import cx from "classnames";
import Loading from "../../../Loading/Loading";

const PublicLocation = ({callBack}) => {

    const [address, setAddress] = useState({});
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(false);

    const updateLocations = (address) => {
        setAddress(address);
        if (address.lat) {
            setLoading(true);
            listPublicLocation({lat: address.lat, lng: address.lng})
                .then(locations => setLocations(locations))
                .catch(e => console.log(e))
                .finally(() => setLoading(false));
        }
    };

    return <Fragment>
        <form onSubmit={e => e.preventDefault()}>
            <AddressSearch
                entity={address}
                setEntity={address => updateLocations(address)}
                label="Search Public Locations Close To"
                save={false}/>
        </form>

        {loading && <Loading/>}
        {locations && locations.map(loc =>
            <div className={cx('box', [styles.addBox])} key={loc.id} onClick={() => callBack(loc)}>
                <div><span className='has-text-weight-bold'>{loc.name}</span> - {loc.rating}</div>
                <div>{loc.address}</div>
            </div>)}
    </Fragment>;

};

export default PublicLocation;