import {login, logout, register} from './Api/Login';


// @todo this should be moved to app state
const Auth = {
    isAuthenticated: false,

    user: null,

    init: () => {
        let user = localStorage.getItem('user');
        Auth.isAuthenticated = !!user;
        Auth.user = user
    },

    authenticate: (email, password) => login(email, password),

    signOut: () => logout(),

    create: (username, email, password, passwordConfirmation) => register(username, email, password, passwordConfirmation)
};

export default Auth;