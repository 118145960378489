import React, {useState} from 'react';
import {useHistory, useLocation, Link} from "react-router-dom";
import Auth from "../../Helpers/Authentication";
import TextField from "../Form/TextField";
import cx from "classnames";

const Login = () => {
    const history = useHistory();
    const location = useLocation();
    const {from} = location.state || {from: {pathname: "/"}};

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const login = () => {
        setLoading(true);
        setErrors({});
        Auth.authenticate({email, password})
            .then(() => history.replace(from))
            .catch((errors) => setErrors(errors))
            .finally(() => setLoading(false));
    };

    return (
        <form onSubmit={e => e.preventDefault()}>
            <TextField placeholder="Your email or username" value={email} onChange={val => setEmail(val)} label="Email or Username" errors={errors.email}/>

            <TextField type="password" placeholder="Your password" value={password} onChange={val => setPassword(val)} label="Password" errors={errors.password}/>

            <div className="field is-grouped">
                <div className="control">
                    <button className={cx('button is-link', {'is-loading': loading})} onClick={() => login()}>Login</button>
                </div>
                <div className="control">
                    <Link to="/">
                        <button className="button is-text">Cancel</button>
                    </Link>
                </div>
            </div>
        </form>
    );
};

export default Login;