import React, {Fragment, useState} from 'react';
import AvailabilityContext from "./Context";
import {setAvailability} from "../../../Helpers/Api/Profile";
import {Link} from "react-router-dom";
import moment from "moment/moment";

const AvailabilityConfirmation = ({setStep}) => {

    const [saved, setSaved] = useState(null);
    const [events, setEvents] = useState([]);
    const {profile, errors, setErrors} = React.useContext(AvailabilityContext);

    const formatProfile = () => {
        let availability = {
            profile: {
                name: profile.name,
                distance: profile.distance,
                friends: profile.friends,
                addressId: profile.address.id
            }
        };

        if (profile.id)
            availability.profile.id = profile.id;

        const formatDate = date => moment(date).format('YYYY-MM-DD HH:mm:ss');

        if (profile.recurrence === 'rec') {
            let recurrences = {};
            profile.weekDays.forEach((day, index) => day.active && (
                recurrences[index] = {
                    day: index,
                    start: formatDate(day.start),
                    end: formatDate(day.end),
                }));
            availability.recurrences = recurrences;
        }
        else
            availability.availability = {
                start: formatDate(profile.singleDay.start),
                end: formatDate(profile.singleDay.end),
            };

        return availability;
    };

    if (saved === null) {
        setSaved(false);
        setAvailability(formatProfile())
            .then(({events}) => {
                setSaved(true);
                setEvents(events);
            })
            .catch(errors => {
                if (errors['profile.name'])
                    setStep(0);
                else if (errors['availability.start'] || errors['availability.end'] || Object.keys(errors).some(e => e.indexOf('recurrences') !== -1))
                    setStep(1);
                else if (errors['profile.distance'] || errors['profile.addressId'])
                    setStep(2);
                else if (errors['profile.friends'])
                    setStep(3);
                setErrors(errors);
            });
    }

    return (<div>
        <h1 className="subtitle">Availability Confirmation</h1>
        {saved && !errors.length &&
        <Fragment>
            <div>You have successfully updated your availability.</div>
            {events.length === 0 && <div>
                <div>Once a group has been created we will send you a notification. Meanwhile you can update the rest of your profile or invite friends to help generate the group quicker.</div>
                <div className='buttons'>
                    <Link className='button' to='/profile/game'>Update Games List</Link>
                    <Link className='button' to='/'>View Events</Link>
                </div>
            </div>}
            {events.length > 0 && <div>
                You have been successfully matched with the following event{events.length > 1 ? 's' : ''}:
                <div className='buttons'>
                    {events.map((eventId, index) => <Link to={'/event/' + eventId} className='button' key={index}>Event #{eventId}</Link>)}
                </div>
            </div>}
        </Fragment>}
    </div>);
};

export default AvailabilityConfirmation;