import {apiUrl, handleResponse, AuthenticationHeaders} from "../Api";

export const getUserDetails = (user) =>
    fetch(`${apiUrl}/player/detail`, AuthenticationHeaders('POST', user))
        .then(handleResponse);

export const getUserGames = (user) =>
    fetch(`${apiUrl}/player/game`, AuthenticationHeaders('POST', user))
        .then(handleResponse);

export const getUserFriends = (user) =>
    fetch(`${apiUrl}/player/friend`, AuthenticationHeaders('POST', user))
        .then(handleResponse);

