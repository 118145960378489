import React, {useState} from "react";
import {Link} from "react-router-dom";
import BggLogo from './BGG.jpeg';
import styles from './Styles.module.scss';
import {bggLogin} from "../../../Helpers/Api/Bgg";
import TextField from "../../../Components/Form/TextField";
import cx from "classnames";

const BggLogin = ({setSession}) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const login = (e) => {
        setLoading(true);
        setErrors({});
        bggLogin({username, password})
            .then(({loginSuccess}) => setSession(loginSuccess))
            .catch((errors) => setErrors(errors))
            .finally(() => setLoading(false));
    };

    return (
        <form onSubmit={e => e.preventDefault()}>
            <img src={BggLogo} className={styles.bggLogo} alt="BGG Logo"/>
            <TextField placeholder="Your username" value={username} onChange={e => setUsername(e)} label="Username" errors={errors.username}/>

            <TextField type="password" placeholder="Your password" value={password} onChange={e => setPassword(e)} label="Password" errors={errors.password}/>

            <div className="field is-grouped">
                <div className="control">
                    <button className={cx('button is-link', {'is-loading': loading})} onClick={() => login()}>Login</button>
                </div>
                <div className="control">
                    <Link to="/">
                        <button className="button is-text">Cancel</button>
                    </Link>
                </div>
            </div>
        </form>
    );
};

export default BggLogin;