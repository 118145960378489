import React, {useState} from 'react';
import Map from "../../../Components/Map/OpenStreets";
import EventCreateContext from "./Context";
import AddLocation from "../../../Components/Location/AddLocation";
import Loading from "../../../Components/Loading/Loading";

const EventCreateLocation = ({onNext, onBack}) => {

    const {event, setEvent, errors} = React.useContext(EventCreateContext);
    const [markers, setMarkers] = useState(null);

    const addLocation = location => {
        setEvent({...event, ...{location}});
        setMarkers([{
            popup: 'Event Location',
            location: location.location
        }]);
    };

    const resetLocation = () => {
        setEvent({...event, ...{location: null}});
        setMarkers([]);
    };

    return (
        <div>
            <div className='subtitle'>Select a value for the event</div>
            <div className="columns">
                <div className='column'>
                    {!event.location && <AddLocation callBack={addLocation}/>}
                    {!event.location && errors.locationId && <div className="help is-danger">{errors.locationId[0]}</div>}

                    {event.location &&
                    <div>
                        <div className='has-text-weight-bold'>{event.location.name}</div>
                        <div>{event.location.address}</div>
                        <div>{event.location.rating}</div>
                        <div className='buttons'>
                            <button className='button is-text' onClick={() => resetLocation()}>Change event Location</button>
                        </div>
                    </div>}
                </div>
                <div className="column">
                    {markers && <Map locations={markers} zoom={10}/>}
                    {!markers && <Loading/>}
                </div>
            </div>

            {event.location && <div className='buttons'>
                <button className="button is-primary" onClick={() => onNext()}>Next</button>
                <button className="button is-text" onClick={() => onBack()}>Back</button>
            </div>}
        </div>
    );
};

export default EventCreateLocation;