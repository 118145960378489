import React, {useEffect, useState} from 'react';
import cx from 'classnames';

const Paginate = ({elements, perPage = 8, perRow = 4, classes, Component}) => {

    const [page, setPage] = useState(0);
    const maxPage = Math.ceil(elements.length / perPage);
    const rowClass = ['', 'is-full', 'is-half', 'is-one-third', 'is-one-quarter', 'is-one-fifth'];

    // Set page to first page if we change the elements
    useEffect(() => setPage(0), [elements]);

    return <div>
        <div className={cx('columns is-multiline', classes)}>
            {elements.slice(page * perPage, (page + 1) * perPage).map((element, id) =>
                <div className={"column " + rowClass[perRow]} key={id}>
                    <Component {...element}/>
                </div>
            )}
        </div>

        {maxPage > 1 &&
        <nav className="pagination is-rounded is-centered" role="navigation">
            <a
                className="pagination-previous"
                disabled={!page}
                onClick={() => page > 0 && setPage(page - 1)}>Previous</a>
            <a
                className="pagination-next"
                disabled={page === maxPage - 1}
                onClick={() => page + 1 < maxPage && setPage(page + 1)}>Next page</a>
            <ul className="pagination-list">
                {[...Array(maxPage).keys()].map((key) =>
                    <li key={key} onClick={() => setPage(key)}>
                        <a className={cx('pagination-link', {'is-current': key === page})}>{key + 1}</a>
                    </li>
                )}
            </ul>
        </nav>
        }
    </div>;
};

export default Paginate;