import React, {useState} from "react";
import {getUserGames} from "../../../Helpers/Api/Player";
import Paginate from "../../../Components/Paginate/Paginate";
import Game from "../../../Components/Game/Game";

const PlayerGame = ({userId}) => {

    const [games, setGames] = useState(null);
    const [search, setSearch] = useState('');

    if (games === null)
        getUserGames({id: userId})
            .then((list) => setGames(list));

    const filteredGames = games ? games.filter(game => game.name.toLowerCase().includes(search.toLowerCase())) : [];

    return (
        <div>
            <div className='columns is-mobile'>
                <div className='column subtitle'>Games</div>
                <div className="column field is-2-desktop">
                    <p className="control has-icons-left">
                        <input className="input" name={search} type='text' placeholder='Search' value={search} onChange={e => setSearch(e.target.value)}/>
                        <span className="icon is-small is-left">
                      <i className="fa fa-search"/>
                    </span>
                    </p>
                </div>
            </div>

            <Paginate elements={filteredGames} perRow={5} perPage={50} Component={Game}/>
        </div>
    );
};

export default PlayerGame;