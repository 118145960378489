import React from 'react';
import ButtonSelect from "../../../Components/Form/ButtonSelect";
import AvailabilityContext from "./Context";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextField from "../../../Components/Form/TextField";

const EventCreateDetails = ({onNext}) => {
    const {event, setEvent, errors} = React.useContext(AvailabilityContext);

    return (<form onSubmit={e => e.preventDefault()}>
        <h1 className="subtitle">Event Details</h1>

        <TextField
            placeholder="Name"
            value={event.name}
            onChange={name => setEvent({...event, ...{name}})}
            label="Event Name" errors={errors.name}/>

        <label className="label">Select start and end</label>
        <DatePicker
            selected={event.start}
            onChange={start => setEvent({...event, ...{start}})}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MMM d, yy h:mm aa"
            className="input"/>
        {errors.start && <div className="help is-danger">{errors.start[0]}</div>}

        <DatePicker
            selected={event.end}
            onChange={end => setEvent({...event, ...{end}})}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MMM d, yy h:mm aa"
            className="input"/>
        {errors.end && <div className="help is-danger">{errors.end[0]}</div>}

        <ButtonSelect
            label="Type"
            value={event.public}
            options={[{value: 0, label: 'Private'}, {value: 1, label: 'Public'}]}
            setValue={val => setEvent({...event, ...{public: val}})}
            errors={errors.public}/>
        {event.public === 0 && <div>People searching for groups will not be able to automatically join private events unless they are invited.</div>}

        <TextField
            placeholder="Fee"
            value={event.fee}
            onChange={fee => setEvent({...event, ...{fee}})}
            label="Fee" errors={errors.fee}/>

        <button className="button is-primary" onClick={() => onNext()}>Next</button>
    </form>);
};

export default EventCreateDetails;