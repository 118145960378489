import React from 'react';
import styles from './Assets/Game.module.scss';

const Game = ({gameId, image, name, year, playersMax, playersMin, playTime, rating, complexity}) => (
    <div className="box">

        <div className={styles.title}><a href={'https://www.boardgamegeek.com/boardgame/' + gameId} target='_blank' rel="nofollow noopener noreferrer">{name}</a> {year}</div>

        <a href={'https://www.boardgamegeek.com/boardgame/' + gameId} target='_blank' rel="nofollow noopener noreferrer" title={name}>
            <div className={styles.image} style={{backgroundImage: `url(${image})`}}/>
        </a>

        <div className='columns is-mobile has-text-centered is-gapless is-size-7'>
            <div className="column" title='Number of players'><i className="fa fa-users" /><br/>{playersMin} - {playersMax}</div>
            <div className="column" title='Play time'><i className="fa fa-clock-o"/><br/>{playTime} min</div>
            <div className="column" title='Rating'><i className="fa fa-star-o"/><br/>{rating}</div>
            <div className="column" title='Complexity'><i className="fa fa-balance-scale"/><br/>{complexity}</div>
        </div>
    </div>
);

export default Game;