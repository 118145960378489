import React, {Fragment, useState} from 'react';
import cx from "classnames";
import styles from './MyLocation.module.scss';
import {listUserLocation} from "../../../../Helpers/Api/Location";
import Loading from "../../../Loading/Loading";

const MyLocation = ({callBack}) => {

    const [locations, setLocations] = useState(null);

    if (locations === null) {
        listUserLocation()
            .then((data) => setLocations(data))
            .catch(errors => console.log(errors));
    }

    return <Fragment>
        {locations === null && <Loading />}
        {locations && locations.map(loc =>
            <div className={cx('box', [styles.addBox])} key={loc.id} onClick={() => callBack(loc)}>
                <div><span className='has-text-weight-bold'>{loc.name}</span> - {loc.rating}</div>
                <div>{loc.address}</div>
            </div>)}
    </Fragment>;
};

export default MyLocation;