import React, {Fragment, useState} from 'react';
import Tabs from "../Tabs/Tabs";
import NewLocation from "./Assets/AddLocation/NewLocation";
import MyLocation from "./Assets/AddLocation/MyLocation";
import PublicLocation from "./Assets/AddLocation/PublicLocation";

const AddLocation = ({callBack, cancel = false}) => {

    const [tabIndex, setTabIndex] = useState(null);
    const tabs = [
        {Page: MyLocation, title: 'My Locations'},
        {Page: PublicLocation, title: 'Public Locations'},
        {Page: NewLocation, title: 'New Location'},
    ];

    return (
        <Fragment>
            <Tabs
                tabs={tabs}
                pageProps={{callBack}}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}/>

            {tabIndex === null &&
            <div className='content'>From the menu above please select:
                <ul>
                    <li><span className='has-text-weight-bold'>My Locations</span>: Select from a list of previously used locations</li>
                    <li><span className='has-text-weight-bold'>Public Locations</span>: Select from a list of public locations</li>
                    <li><span className='has-text-weight-bold'>New Location</span>: Add a bran new location</li>
                </ul>
            </div>}

            {cancel && <button className='button is-text' onClick={() => callBack(null)}>Cancel</button>}
        </Fragment>);
};

export default AddLocation;