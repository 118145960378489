import React, {useState} from 'react';
import cx from "classnames";
import TextField from "../Form/TextField";
import FriendSearch from "../Autocomplete/FriendSearch";
import {inviteFriend} from "../../Helpers/Api/Friend";

const AddPlayer = ({players, setPlayers}) => {

    const [errors, setErrors] = useState({});
    const [newPlayer, setNewPlayer] = useState({});
    const [loading, setLoading] = useState(false);

    const invitePlayer = () => {
        inviteFriend(newPlayer)
            .then((friend) => {
                setNewPlayer({});
                setErrors({});
                addPlayer(friend)
            })
            .catch((errors) => setErrors(errors))
            .finally(() => setLoading(false));
    };

    const addPlayer = (player) => setPlayers([...players, ...[player]]);

    return (<div>

        <FriendSearch label="Search Friend" setEntity={player => player && addPlayer(player)} errors={errors} setErrors={setErrors}/>

        ---- OR ----

        <form onSubmit={e => e.preventDefault()}>

            <TextField placeholder="Name" value={newPlayer.name} onChange={name => setNewPlayer({...newPlayer, ...{name}})} label="Name" errors={errors.name}/>
            <TextField placeholder="Email" value={newPlayer.email} onChange={email => setNewPlayer({...newPlayer, ...{email}})} label="Email" errors={errors.email}/>

            <button className={cx('button is-link', {'is-loading': loading})} onClick={() => invitePlayer()}>Invite Player</button>
        </form>
    </div>);

};

export default AddPlayer;