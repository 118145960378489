import React from 'react';
import EventCreateContext from "./Context";
import GameSearch from "../../../Components/Autocomplete/GameSearch";
import Game from "../../../Components/Game/Game";

const EventCreateGames = ({onNext, onBack}) => {

    const {event, setEvent, errors, setErrors} = React.useContext(EventCreateContext);

    const addGame = ({game}) => {
        let {games} = {...event};
        games = games || [];
        games.push(game);
        setEvent({...event, ...{games}});
    };

    return (<div>
        <GameSearch
            entity={null}
            setEntity={option => option && addGame(option)}
            label="Games"
            errors={errors.games}
            setErrors={games => setErrors({...errors, ...{games}})}/>

        {event.games &&
        <div className='columns is-multiline'>
            {event.games.map(game =>
                <div className="column is-one-quarter" key={game.gameId}>
                    <Game {...game}/>
                </div>
            )}
        </div>
        }

        <button className="button is-primary" onClick={() => onNext()}>Next</button>
        <button className="button is-text" onClick={() => onBack()}>Back</button>
    </div>);
};

export default EventCreateGames;