import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Auth from "../../Helpers/Authentication";

const GuestRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={(props) => !Auth.isAuthenticated ? (<Component {...props} />) : (<Redirect to={"/"}/>)}
    />
);

export default GuestRoute;