import React, {useState} from "react";
import EventLocation from "./Assets/EventCreate/Location";
import EventGames from "./Assets/EventCreate/Game";
import EventDetails from "./Assets/EventCreate/Details";
import EventAttendees from "./Assets/EventCreate/Attendee";
import EventConfirmation from "./Assets/EventCreate/Confirmation";
import EventContext from './Assets/EventCreate/Context';
import Wizard from "../Components/Wizard/Wizard";

const EventCreatePage = () => {

    const [event, setEvent] = useState({});
    const [errors, setErrors] = useState({});

    const steps = [
        {Page: EventDetails, title: 'Details'},
        {Page: EventLocation, title: 'Location'},
        {Page: EventAttendees, title: 'Attendees'},
        {Page: EventGames, title: 'Games'},
        {Page: EventConfirmation, title: 'Confirmation'},
    ];

    return <EventContext.Provider value={{event, setEvent, errors, setErrors}}>
        <Wizard steps={steps} title='Create Event'/>
    </EventContext.Provider>;
};

export default EventCreatePage;