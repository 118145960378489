import React, {useEffect, useState} from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import ReactDOMServer from "react-dom/server";

const Map = ({locations, activeMarker = null, height = 400, markerOpacity = 1, pad = 0.1, zoom = 0}) => {

    const [map, setMap] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [layers] = useState([]);

    markers.forEach((marker, markerIndex) => {
        if (markerIndex === activeMarker) {
            marker.setOpacity(1);
            marker.setZIndexOffset(1000);
        } else {
            marker.setZIndexOffset(0);
            marker.setOpacity(markerOpacity);
        }
    });

    useEffect(() => {
        const newMap = L.map('map-example-container');
        setMap(newMap);
        L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
            attribution: '<a href="https://carto.com/" rel="nofollow">Carto</a> | <a href="https://openstreetmap.org/" rel="nofollow">OSM</a>'
        }).addTo(newMap);
    }, []);

    useEffect(() => {
        markers.forEach(marker => map.removeLayer(marker));
        let newMarkers = [];
        if (map && locations.length) {
            const getIcon = (url) => L.icon({
                iconUrl: url || 'https://i.pinimg.com/originals/61/a4/13/61a413a5dd1d75892a9d738e8f531ae9.png',
                iconSize: [40, 40],
                iconAnchor: [20, 40],
                popupAnchor: [-3, -40],
                shadowUrl: iconShadow,
                shadowSize: [40, 40],
                shadowAnchor: [0, 45]
            });

            const findBestZoom = () => {map.fitBounds(L.featureGroup(layers).getBounds().pad(pad)); zoom && map.setZoom(zoom)};
            const onEachFeature = (feature, layer) => feature.properties.popup && layer.bindPopup(ReactDOMServer.renderToString(feature.properties.popup));
            const pointToLayer = (feature, geometry) => {
                const icon = getIcon(feature.properties.icon);
                const marker = L.marker(geometry, {icon, opacity: markerOpacity});
                newMarkers.push(marker);
                return marker
            };
            const setLocation = (el) => ({
                type: "Feature",
                properties: {popup: el.popup, icon: el.icon || null},
                geometry: el.location
            });

            locations.forEach((el) => {
                const location = setLocation(el);
                const marker = L.geoJson(location, {onEachFeature, pointToLayer}).addTo(map);
                layers.push(marker)
            });
            findBestZoom();
        }

        setMarkers(newMarkers);
    }, [map, locations.length]); // eslint-disable-line react-hooks/exhaustive-deps

    return <div id="map-example-container" style={{height}}/>
};

export default Map;