import React from 'react';
import EventCreateContext from "./Context";
import AddPlayer from "../../../Components/Player/AddPlayer";
import User from "../../../Components/User/User";

const EventCreateAttendees = ({onNext, onBack}) => {

    const {event, setEvent} = React.useContext(EventCreateContext);

    return (
        <div>
            <div className="columns">
                <div className="column is-half">
                    <div>Would you like to invite some friends to this event??</div>
                    {event.players && event.players.map(friend => <User {...friend} key={friend.userId}/>)}
                    {!event.players && <div>You have not invited anyone. Please use the form to add more players</div>}
                </div>
                <div className="column is-half">
                    <AddPlayer players={event.players || []} setPlayers={players => setEvent({...event, ...{players}})}/>
                </div>
            </div>

            <button className="button is-primary" onClick={() => onNext()}>Next</button>
            <button className="button is-text" onClick={() => onBack()}>Back</button>
        </div>
    );
};

export default EventCreateAttendees;