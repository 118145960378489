import React, {useState} from 'react';
import styles from './Assets/Wizard.module.scss';
import cx from 'classnames';

const Wizard = ({steps, title, freeBrowse = false}) => {

    const [stepIndex, setStepIndex] = useState(0);
    const [stepCompleted, setStepCompleted] = useState(0);
    const {Page} = steps[stepIndex];

    const setStep = (index) => (stepCompleted >= index || freeBrowse) && setStepIndex(index);

    const onBack = () => stepIndex > 0 && setStepIndex(stepIndex - 1);

    const onNext = () => {
        const newStep = stepIndex + 1;
        if (newStep < steps.length) {
            setStepIndex(newStep);
            if (newStep > stepCompleted)
                setStepCompleted(newStep);
        }
    };

    return (
        <div className={cx("columns is-marginless", styles.container)}>
            <aside className={cx("menu column is-one-quarter is-hidden-mobile", styles.aside)}>
                <p className="menu-label">{title}</p>
                <ul className="menu-list">
                    {steps.map((step, index) =>
                        <li key={index}>
                            <a className={cx({[styles.isDisabled]: index > stepCompleted && !freeBrowse}, {'is-active': index === stepIndex})} onClick={() => setStep(index)}>{step.title}</a>
                        </li>)}
                </ul>
            </aside>
            <div className="column is-hidden-desktop">
                <div className="subtitle is-marginless has-text-centered">{steps[stepIndex].title}</div>
                <progress className="progress is-small is-info" value={Math.round(stepIndex / (steps.length - 1) * 100)} max="100" />
            </div>

            <div className="column">
                {Page && <Page onNext={() => onNext()} onBack={() => onBack()} setStep={e => setStep(e)}/>}
            </div>
        </div>
    );
};

export default Wizard;