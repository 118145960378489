import React, {useState} from "react";
import AvailabilityLocation from './Assets/Availability/Location';
import AvailabilityDate from './Assets/Availability/Date';
import AvailabilityFriends from './Assets/Availability/Friends';
import AvailabilityName from './Assets/Availability/Name';
import AvailabilityConfirmation from './Assets/Availability/Confirmation';
import AvailabilityContext from './Assets/Availability/Context';
import Wizard from "../Components/Wizard/Wizard";
import {getAvailability} from "../Helpers/Api/Profile";
import Loading from "../Components/Loading/Loading";

const ProfileAvailabilityPage = () => {

    const [profile, setProfile] = useState({
        id: null,
        name: '',
        address: {},
        distance: '',
        friends: null,
        singleDay: {start: null, end: null},
        weekDays: [
            {day: 'Monday', start: null, end: null, active: false},
            {day: 'Tuesday', start: null, end: null, active: false},
            {day: 'Wednesday', start: null, end: null, active: false},
            {day: 'Thursday', start: null, end: null, active: false},
            {day: 'Friday', start: null, end: null, active: false},
            {day: 'Saturday', start: null, end: null, active: false},
            {day: 'Sunday', start: null, end: null, active: false},
        ],
        recurrence: [],
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);

    const steps = [
        {Page: AvailabilityName, title: 'Name'},
        {Page: AvailabilityDate, title: 'Date'},
        {Page: AvailabilityLocation, title: 'Location'},
        {Page: AvailabilityFriends, title: 'Friends'},
        {Page: AvailabilityConfirmation, title: 'Confirmation'},
    ];

    const getSingleDay = (newProfile) => !newProfile.recurrences.length && newProfile.availabilities.length ?
        {start: new Date(newProfile.availabilities[0].start.replace(' ', 'T') + 'Z'), end: new Date(newProfile.availabilities[0].end.replace(' ', 'T') + 'Z')}
        : {start: null, end: null};

    const getWeekDays = (newProfile) => {
        let weekDays = profile.weekDays;
        if (newProfile.recurrences.length) {
            newProfile.recurrences.forEach((recurrence) => {
                weekDays[recurrence.day].start = new Date('2000-01-01 ' + recurrence.start);
                weekDays[recurrence.day].end = new Date('2000-01-01 ' + recurrence.end);
                weekDays[recurrence.day].active = true;
            });
        }
        return weekDays;
    };

    if (loading) {
        getAvailability()
            .then(newProfile => {
                setLoading(false);
                if (newProfile.id) {
                    setProfile({
                        ...profile, ...{
                            id: newProfile.id,
                            name: newProfile.name,
                            distance: newProfile.distance,
                            address: {id: newProfile.addressId},
                            friends: newProfile.friends,
                            recurrence: newProfile.recurrences.length ? 'rec' : 'day',
                            singleDay: getSingleDay(newProfile),
                            weekDays: getWeekDays(newProfile)
                        }
                    });
                }
            });
        return <Loading />;
    }

    return (
        <AvailabilityContext.Provider value={{profile, setProfile, errors, setErrors}}>
            <Wizard steps={steps} title='Availability Steps' freeBrowse={profile.id > 0}/>
        </AvailabilityContext.Provider>
    );
};

export default ProfileAvailabilityPage;