import React, { useEffect, useState } from "react";
import { getGames } from "../Helpers/Api/Profile";
import BggLogin from "./Assets/Bgg/Login";
import { bggHasSession, bggUpdateCollection } from "../Helpers/Api/Bgg";
import Paginate from "../Components/Paginate/Paginate";
import Game from "../Components/Game/Game";
import { Slider, RangeSlider } from "rsuite"
import TextField from "../Components/Form/TextField";

const ProfileGamesPage = () => {

    const [games, setGames] = useState([]);
    const [filteredGames, setFilteredGames] = useState([]);
    const [bggSession, setBggSession] = useState(null);
    const [displayBggLogin, setDisplayBggLogin] = useState(null);

    const [gameName, setGameName] = useState('');
    const [complexity, setComplexity] = useState([0, 5]);
    const [playerCount, setPlayerCount] = useState(3);
    const [playTime, setPlayTime] = useState([20, 240]);
    const [playersBest, setPlayersBest] = useState(false);

    const hasGames = games?.length > 0;

    useEffect(() => {
        getGames()
            .then((list) => {
                setGames(list);
                setFilteredGames(list);
            })

        bggHasSession().then(({ hasSession }) => setBggSession(hasSession))
    }, [])

    const importGames = () =>
        bggUpdateCollection()
            .then((collection) => {
                if (collection.hasUpdated && collection.games) {
                    setGames(collection.games);
                    setFilteredGames(collection.games);
                }
            })

    const totalPrice = () => {
        let total = 0;
        if (games?.length)
            games.forEach(game => total += parseFloat(game.price));
        return total.toFixed(2);
    };

    useEffect(() => {
        let newFilteredGames = []
        const playerCountString = playerCount.toString()
        const lowerGameName = gameName.trim().toLowerCase()

        if (playersBest)
            newFilteredGames = games.filter(g =>
                g.playersBest === playerCount &&
                (g.playTime === null || (g.playTime <= playTime[1] && g.playTime >= playTime[0])) &&
                (g.complexity === null || (g.complexity <= complexity[1] && g.complexity >= complexity[0])) &&
                g.name.toLowerCase().includes(lowerGameName))
        else
            newFilteredGames = games.filter(g => (g.playersRecommended === null || g.playersRecommended?.split(',').includes(playerCountString)) &&
                    (g.playTime === null || (g.playTime <= playTime[1] && g.playTime >= playTime[0])) &&
                    (g.complexity === null || (g.complexity <= complexity[1] && g.complexity >= complexity[0])) &&
                    g.name.toLowerCase().includes(lowerGameName))
        setFilteredGames(newFilteredGames)
    }, [playerCount, playTime, complexity, playersBest, games, gameName])

    return (
        <div>
            {!hasGames && <div>Your games list is empty</div>}
            {!displayBggLogin && !bggSession && <div>Would you like to import your game list from BGG? <button className="button is-info" onClick={() => setDisplayBggLogin(true)}>Import</button></div>}
            {displayBggLogin && !bggSession && <BggLogin setSession={session => setBggSession(session)} />}

            {bggSession && <div className="field"><button className="button is-info" onClick={() => importGames()}>Update your collection from BGG</button></div>}

            {games?.length > 0 &&
                <div>
                    {false && <table className="table is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {games.map((game, index) =>
                                <tr key={index}>
                                    <td>{game.name}</td>
                                    <td>{game.price}</td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th>{totalPrice()}</th>
                            </tr>
                        </tfoot>
                    </table>}
                    <div className="field" style={{ paddingBottom: '20px' }}>
                        <label className="label">Player Count</label>
                        <Slider min={1} max={10}
                            graduated
                            step={1}
                            renderMark={mark => mark} defaultValue={playerCount} onChange={value => setPlayerCount(value)} />

                        <label className="label">
                            <span style={{ paddingRight: '10px' }}>Show Only Best For Player Count</span>
                            <input type="checkbox" value={playersBest} onClick={() => setPlayersBest(!playersBest)} />
                        </label>

                        <label className="label">Complexity</label>
                        <RangeSlider min={1} max={5}
                            graduated
                            progress
                            step={0.5}
                            renderMark={mark => mark} defaultValue={complexity} onChange={value => setComplexity(value)} />

                        <label className="label">Play Time</label>
                        <RangeSlider min={20} max={360}
                            graduated
                            progress
                            step={20}
                            renderMark={mark => mark} defaultValue={playTime} onChange={value => setPlayTime(value)} />

                        <TextField placeholder="Name" value={gameName} onChange={name => setGameName(name)} label="Game Name" />

                    </div>
                    <Paginate elements={filteredGames} Component={Game} />
                </div>}
        </div>
    );
};

export default ProfileGamesPage;