import React from 'react';
import Paginate from "../../../Components/Paginate/Paginate";
import GameMini from "../../../Components/Game/GameMini";
import styles from './Style/About.module.scss';
import cx from 'classnames';

const PlayerAbout = ({user, games}) => {
    return (
        <div className="columns">
            <div className="column is-one third">
                <div className={cx("box", [styles.boxHeight])}>
                    <div className="subtitle">About</div>

                    <img src='https://c7.uihere.com/files/340/946/334/avatar-user-computer-icons-software-developer-avatar.jpg'/>
                    <div className="subtitle is-4">{user.name}</div>
                    <div>
                        <label className='label'>Honor</label>
                        <i className='fa fa-star-o'/> {user.honor}/100
                    </div>
                    <div className='buttons'>
                        <button className="button">
                            <span className="icon is-small">
                              <i className="fa fa-comment-o"/>
                            </span>
                            <span>Message</span>
                        </button>
                        <button className="button is-primary">
                            <span className="icon is-small">
                              <i className="fa fa-user-plus"/>
                            </span>
                            <span>Add Friend</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="column is-one third">
                <div className={cx("box", [styles.boxHeight])}>
                    <div className="subtitle">Games</div>
                    <Paginate elements={games} Component={GameMini} perRow={3} perPage={9} classes='is-mobile'/>
                </div>
            </div>
            <div className="column is-one third">
                <div className={cx("box", [styles.boxHeight])}>
                    <div className="subtitle">Stats</div>
                    <img src='https://d1whtlypfis84e.cloudfront.net/guides/wp-content/uploads/2018/12/24160114/LOSDOS1-1024x767.png' />
                </div>
            </div>
        </div>
    );
};

export default PlayerAbout;