import React from 'react';
import {Link} from "react-router-dom";
import EventContext from "./Context";

const EventDetails = ({onNext}) => {

    const {event} = React.useContext(EventContext);
    const statuses = ['Pending', 'Confirmed', 'Cancelled'];
    const eventStatuses = ['Unconfirmed', 'Confirmed', 'Cancelled'];

    const locationInfo = event.locationId ? 'Confirmed' : `Unconfirmed (${event.suggestedLocations.length} option${event.suggestedLocations.length > 1 ? 's' : ''})`;

    return (
        <div>
            <div className="columns">
                <div className="column">
                    <div>Name: {event.name}</div>
                    <div>Starts: {event.start}</div>
                    <div>Ends: {event.end}</div>
                    <div>Type: {event.public ? 'Public' : 'Private'}</div>
                    <div>Location: {locationInfo}</div>
                    <div>Entry fee: {event.fee || (event.fee === 0 ? 'Free' : 'Unknown')}</div>
                    <div>Status: {eventStatuses[event.status]}</div>
                </div>
                <div className="column">
                    {event.attendees.map((attendee, key) =>
                        <div key={key}>
                            <div>
                                <Link to={`/player/${attendee.username}`}>{attendee.username}</Link> - {statuses[attendee.status] || ''}
                            </div>
                        </div>)}
                </div>
            </div>

            <button className="button is-primary" onClick={() => onNext()}>Next</button>
        </div>
    );
};

export default EventDetails;