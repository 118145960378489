import React from 'react';
import Header from './Sections/Header';
import Routes from './Sections/Routes';
import {BrowserRouter} from 'react-router-dom';
import Auth from './Helpers/Authentication';
import 'bulma';
import 'font-awesome/css/font-awesome.min.css';
import 'rsuite/dist/styles/rsuite-default.min.css';
import AppContext from './AppContext';

const App = () => {
    Auth.init();
    const user = JSON.parse(Auth.user);
    return (
        <AppContext.Provider value={{user}}>
            <BrowserRouter>
                <Header/>
                <Routes/>
            </BrowserRouter>
        </AppContext.Provider>
    );
};

export default App;