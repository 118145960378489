import {apiUrl, handleResponse, AuthenticationHeaders} from "../Api";

export const getAvailability = () =>
    fetch(`${apiUrl}/profile/availability/get`, AuthenticationHeaders())
        .then(handleResponse);

export const setAvailability = (availability) =>
    fetch(`${apiUrl}/profile/availability/update`, AuthenticationHeaders('POST', availability))
        .then(handleResponse);

export const getNotificationPreferences = () =>
    fetch(`${apiUrl}/profile/notification/get`, AuthenticationHeaders())
        .then(handleResponse);

export const updateNotificationPreferences = (preference) =>
    fetch(`${apiUrl}/profile/notification/update`, AuthenticationHeaders('POST', preference))
        .then(handleResponse);

export const getGames = () =>
    fetch(`${apiUrl}/profile/game/list`, AuthenticationHeaders())
        .then(handleResponse);