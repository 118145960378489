import {apiUrl, handleResponse, AuthenticationHeaders} from "../Api";

export const listPublicEvents = (location) =>
    fetch(`${apiUrl}/event/public`, AuthenticationHeaders('POST', location))
        .then(handleResponse);

export const listMyEvents = (location) =>
    fetch(`${apiUrl}/event/my-list`, AuthenticationHeaders('POST', location))
        .then(handleResponse);

export const getEvent = (eventId) =>
    fetch(`${apiUrl}/event/get/${eventId}`, AuthenticationHeaders())
        .then(handleResponse);

export const createEvent = (event) =>
    fetch(`${apiUrl}/event/add`, AuthenticationHeaders('POST', event))
        .then(handleResponse);

export const createEventMessage = (message) =>
    fetch(`${apiUrl}/event/message/add`, AuthenticationHeaders('POST', message))
        .then(handleResponse);

export const voteEventLocation = (vote) =>
    fetch(`${apiUrl}/event/location/vote`, AuthenticationHeaders('POST', vote))
        .then(handleResponse);

export const addEventLocation = (eventLocation) =>
    fetch(`${apiUrl}/event/location/add`, AuthenticationHeaders('POST', eventLocation))
        .then(handleResponse);


