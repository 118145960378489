import Auth from './Authentication'

export const apiUrl = window.location.href.indexOf('http://localhost:3000') === 0? 'http://localhost:8000' : 'https://game-api.vlas.com.au';

export const AuthenticationHeaders = (method = 'GET', body = null) => {
    let request = {method, headers: {'Content-Type': 'application/json'}};

    const user = JSON.parse(localStorage.getItem('user'));

    if (user?.api_key) request.headers.Authorization = 'Basic ' + user.api_key;

    if (method === 'POST') request.body = JSON.stringify(body);

    return request;
};

export const handleResponse = (response) =>
    response.text().then(text => {
        try {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    Auth.signOut();
                    //location.reload(true);
                }
                const error = (data) || response.statusText;
                return Promise.reject(error);
            }
            return data;
        } catch (e) {
            console.log(text);
            return Promise.reject(text);
        }
    });