import React from 'react';
import AddressSearch from '../../../Components/Autocomplete/AddressSearch';
import AvailabilityContext from './Context';
import TextField from "../../../Components/Form/TextField";

const AvailabilityLocation = ({onNext, onBack}) => {

    const {profile, setProfile, errors, setErrors} = React.useContext(AvailabilityContext);

    return (<form onSubmit={e => e.preventDefault()}>
        <h1 className="subtitle">Availability Page</h1>
        <AddressSearch
            entity={profile.address}
            setEntity={(address) => setProfile({...profile, ...{address}})}
            label="Location"
            errors={errors['profile.addressId']}
            setErrors={addressId => setErrors({...errors, ...{'profile.addressId': addressId}})} />

        <TextField placeholder="Distance" value={profile.distance} onChange={distance => setProfile({...profile, ...{distance}})} label="Distance" errors={errors['profile.distance']}/>

        <button className="button is-primary" onClick={() => onNext()}>Next</button>
        <button className="button is-text" onClick={() => onBack()}>Back</button>
    </form>);
};

export default AvailabilityLocation;