import React, {useState} from 'react';
import {createEventMessage} from "../../../Helpers/Api/Event";
import TextField from "../../../Components/Form/TextField";
import styles from './Style/Message.module.css';
import EventContext from "./Context";

const EventMessages = () => {

    const {event, setEvent} = React.useContext(EventContext);
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});

    const newMessage = () => {
        createEventMessage({message, eventId: event.id})
            .then((message) => {
                setMessage('');
                let {messages} = {...event};
                messages.push(message);
                setEvent({...event, ...{messages}})
            })
            .catch((errors) => setErrors(errors));
    };

    return (
        <div>
            <div className="box">
                <div className={styles.messages}>
                    {event.messages.map((m, key) =>
                        <div key={key}>
                            <div>{m.username} {m.created}</div>
                            <div>{m.message}</div>
                        </div>)}
                    {!event.messages.length && 'There are no messages'}
                </div>

                <form onSubmit={e => e.preventDefault()}>
                    <TextField placeholder='Send Message' value={message} onChange={val => setMessage(val)} errors={errors.message}/>
                    <button className="button is-link" onClick={() => newMessage()}>Send</button>
                </form>
            </div>
        </div>
    );
};

export default EventMessages;