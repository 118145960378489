import React from "react";
import Login from '../Components/User/Login';
import {useLocation} from "react-router-dom";

const RestrictedPage = () => {

    const location = useLocation();
    const {from} = location.state || {from: {pathname: "/"}};

    return (
        <div>
            <p>You must log in to view the page at <code>{from.pathname}</code></p>

            <Login />
        </div>
    );
};

export default RestrictedPage;