import {apiUrl, handleResponse, AuthenticationHeaders} from "../Api";

export const addEventGame = (game) =>
    fetch(`${apiUrl}/event/game/add`, AuthenticationHeaders('POST', game))
        .then(handleResponse);

export const joinEventGame = (game) =>
    fetch(`${apiUrl}/event/game/player/join`, AuthenticationHeaders('POST', game))
        .then(handleResponse);

export const leaveEventGame = (game) =>
    fetch(`${apiUrl}/event/game/player/leave`, AuthenticationHeaders('POST', game))
        .then(handleResponse);
