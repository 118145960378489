import React, {useState} from 'react';
import EventCreateContext from "./Context";
import {createEvent} from "../../../Helpers/Api/Event";
import Loading from "../../../Components/Loading/Loading";
import {useHistory} from "react-router-dom";
import moment from "moment/moment";

const EventCreateConfirmation = ({setStep}) => {

    const {event, errors, setErrors} = React.useContext(EventCreateContext);
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const formatDate = date => moment(date).format('YYYY-MM-DD HH:mm:ss');

    const history = useHistory();

    const formatEvent = () => ({
        name: event.name,
        fee: event.fee,
        locationId: event.location?.id,
        start: formatDate(event.start),
        end: formatDate(event.end),
        public: event.public,
        games: event.games?.map(({gameId}) => gameId) || [],
        attendees: event.players?.map(({userId}) => userId) || [],
        confirm
    });

    if (!loading) {
        setLoading(true);
        createEvent(formatEvent())
            .then((eventId) => history.push('/event/' + eventId))
            .catch(errors => {
                if (!errors.fatal) {
                    if (errors.name || errors.fee || errors.start || errors.end)
                        setStep(0);
                    else if (errors.locationId)
                        setStep(1);
                    setErrors(errors);
                    if (!errors.confirm || errors.error)
                        setLoading(false);
                    setConfirm(false);
                }
            });
    }

    if (errors.fatal)
        return <div>{errors.error[0]}</div>;

    return (
        <div>
            {!errors.confirm && loading && <Loading/>}
            {errors.confirm && <div>{errors.confirm[0]}
                <div>
                    <button className='button is-info' onClick={() => {
                        setConfirm(true);
                        setLoading(false)
                    }}>Confirm
                    </button>
                </div>
            </div>}
        </div>
    );
};

export default EventCreateConfirmation;