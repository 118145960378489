import React from 'react';
import TextField from "../../../Components/Form/TextField";
import AvailabilityContext from "./Context";

const AvailabilityName = ({onNext}) => {
    const {profile, setProfile, errors} = React.useContext(AvailabilityContext);

    return (<form onSubmit={e => e.preventDefault()}>
        <h1 className="subtitle">Profile Name</h1>

        <p>How would you like to call this profile?</p>

        <TextField placeholder="Profile name" value={profile.name} onChange={name => setProfile({...profile, ...{name}})} label="Name" errors={errors['profile.name']}/>

        <button className="button is-primary" onClick={() => onNext()}>Next</button>
    </form>);
};

export default AvailabilityName;