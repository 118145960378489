import React from 'react';
import {Link} from "react-router-dom";
import styles from './Assets/Friend.module.scss';

const Friend = ({name, username, honor, image}) => (
    <div className="box">

        <div className={styles.title}><Link to={`/player/${username}`}>{name} - {username}</Link></div>

        <Link to={`/player/${username}`}>
            <div className={styles.image} style={{backgroundImage: `url(${image || 'https://c7.uihere.com/files/340/946/334/avatar-user-computer-icons-software-developer-avatar.jpg'})`}}/>
        </Link>

        <div className='columns is-mobile has-text-centered is-gapless is-size-7'>
            <div className="column" title='Rating'><i className="fa fa-star-o"/><br/>{honor}</div>
        </div>
    </div>
);

export default Friend;