import React, {useState} from 'react';
import Autocomplete from './Assets/Autocomplete';
import {searchFriend} from '../../Helpers/Api/Friend';

const FriendSearch = ({setEntity, errors, setErrors, label = null}) => {
    const [term, setTerm] = useState('');
    const [options, setOptions] = useState([]);

    const getOptions = (friend) => {
        setEntity(null);
        if (friend.length > 2) {
            searchFriend({friend})
                .then((friends) => {
                    const newOptions = (friends || []).map((friend) => ({
                        value: friend.userId,
                        label: friend.username,
                        friend
                    }));
                    setOptions(newOptions);
                });
        } else
            setOptions([]);
    };

    const selectOption = ({friend}) => {
        setErrors([]);
        setOptions([]);
        setEntity(friend)
    };

    return <Autocomplete
        label={label}
        name="friend"
        placeholder="Friend"
        value={term}
        setValue={setTerm}
        options={options}
        searchCallback={getOptions}
        selectCallback={selectOption}
        errors={errors}
        resetTextPostSelect={true}
        delay={200}/>;
};

export default FriendSearch;