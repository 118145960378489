import React from 'react';
import AvailabilityContext from "./Context";
import ButtonSelect from "../../../Components/Form/ButtonSelect";

const AvailabilityFriends = ({onBack, onNext}) => {
    const {profile, setProfile} = React.useContext(AvailabilityContext);

    return (<form onSubmit={e => e.preventDefault()}>
        <h1 className="subtitle">Availability Friends</h1>

        <ButtonSelect
            value={profile.friends}
            options={[{value: 0, label: 'Anyone'}, {value: 1, label: 'At least a Friend'}, {value: 2, label: 'At least a Favorite Friend'}]}
            setValue={friends => setProfile({...profile, ...{friends}})}/>

        <button className="button is-primary" onClick={() => onNext()}>Next</button>
        <button className="button is-text" onClick={() => onBack()}>Back</button>
    </form>);
};

export default AvailabilityFriends;