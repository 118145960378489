import React, {useState} from "react";
import {getNotificationPreferences, updateNotificationPreferences} from "../Helpers/Api/Profile";
import cx from "classnames";

const ProfileNotificationPage = () => {

    const [preferences, setPreferences] = useState({
        eventInvite: {label: 'Invite to event'},
        eventNearby: {label: 'Event Nearby'}
    });
    const [errors, setErrors] = useState({});

    const [gathered, setGathered] = useState(false);

    if (!gathered) {
        setGathered(true);
        getNotificationPreferences()
            .then(notifications => {
                let newPreferences = {...preferences};
                Object.entries(notifications).forEach(([preference, value]) => {
                    if (newPreferences.hasOwnProperty(preference)) {
                        newPreferences[preference].web = value === 1 || value === 3;
                        newPreferences[preference].email = value >= 2;
                    }
                });
                setPreferences(newPreferences);
            });
    }

    const togglePreference = (preference, type) => {
        let newPreferences = {...preferences};
        newPreferences[preference][type] = !newPreferences[preference][type];
        setPreferences(newPreferences);

        let savePreference = {};
        Object.keys(newPreferences).map(key => savePreference[key] = (newPreferences[key].web ? 1 : 0) + (newPreferences[key].email ? 2 : 0));

        updateNotificationPreferences({...savePreference})
            .catch((errors) => setErrors(errors));
    };

    return (
        <div>
            {Object.entries(preferences).map(([preference, value]) =>
                <div key={preference}>{value.label}
                    <button className={cx("button", {'is-primary': value.web})} onClick={() => togglePreference(preference, 'web')}>Web</button>
                    <button className={cx("button", {'is-primary': value.email})} onClick={() => togglePreference(preference, 'email')}>Email</button>
                </div>
            )}
            {errors.preference && <div className="help is-danger">{errors.preference}</div>}
            {errors.value && <div className="help is-danger">{errors.value}</div>}
        </div>
    );
};

export default ProfileNotificationPage;