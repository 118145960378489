import React, {Fragment} from 'react';
import cx from 'classnames';

const Tabs = ({tabs, pageProps, tabIndex, setTabIndex}) => {

    const {Page, props} = tabs[tabIndex] || {};

    return (
        <Fragment>
            <div className="tabs">
                <ul>
                    {tabs.map((tab, index) =>
                        <li className={cx({'is-active': index === tabIndex})} onClick={() => setTabIndex(index)} key={index}>
                            <a>{tab.title}</a>
                        </li>
                    )}
                </ul>
            </div>
            {Page && <Page {...pageProps} {...props} />}
        </Fragment>
    );
};

export default Tabs;