import React, {useState} from 'react';
import Autocomplete from './Assets/Autocomplete';
import {searchGame} from '../../Helpers/Api/Game';
import styles from './Assets/GameSearch.module.scss';
import cx from 'classnames';

const GameSearch = ({setEntity, errors, setErrors, label = null}) => {
    const [term, setTerm] = useState('');
    const [options, setOptions] = useState([]);

    const getOptions = (searchString) => {
        setEntity(null);
        if (searchString.length > 2) {
            searchGame({game: searchString})
                .then((games) => {
                    const newOptions = (games || []).map(game => ({
                        value: game.gameId,
                        /* eslint-disable */
                        label: <div className='columns is-marginless is-mobile is-gapless'>
                            <img src={game.image} className={styles.image}/>
                            <div className='column'>
                                <div className='has-text-weight-bold'>{game.name}</div>
                                <div className={cx('columns is-mobile has-text-centered is-gapless is-size-7', styles.stats)}>
                                    <div className="column"><i className="fa fa-users"/><br/>{game.playersMin} - {game.playersMax}</div>
                                    <div className="column"><i className="fa fa-clock-o"/><br/>{game.playTime} min</div>
                                    <div className="column"><i className="fa fa-star-o"/><br/>{game.rating}</div>
                                    <div className="column"><i className="fa fa-balance-scale"/><br/>{game.complexity}</div>
                                </div>
                            </div>
                        </div>,
                        /* eslint-enable */
                        game
                    }));
                    setOptions(newOptions);
                });
        } else
            setOptions([]);
    };

    const selectOption = (game) => {
        setErrors([]);
        setOptions([]);
        setEntity(game)
    };

    return <Autocomplete
        label={label}
        name="game"
        placeholder="Game"
        value={term}
        setValue={setTerm}
        options={options}
        searchCallback={getOptions}
        selectCallback={selectOption}
        errors={errors}
        resetTextPostSelect={true}
        delay={200}/>;
};

export default GameSearch;