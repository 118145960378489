import React, {useState} from 'react';
import {addLocation} from "../../../../Helpers/Api/Location";
import cx from "classnames";
import TextField from "../../../Form/TextField";
import AddressSearch from "../../../Autocomplete/AddressSearch";
import ButtonSelect from "../../../Form/ButtonSelect";

const NewLocation = ({callBack}) => {

    const [errors, setErrors] = useState({});
    const [location, setLocation] = useState({});
    const [loading, setLoading] = useState(false);

    const submitLocation = () => {
        setLoading(true);
        const newLocation = {
            addressId: location.address ? location.address.id : [],
            public: location.public,
            name: location.name
        };

        addLocation(newLocation)
            .then((loc) => {
                setLocation({});
                setErrors({});
                callBack(loc)
            })
            .catch((errors) => setErrors(errors))
            .finally(() => setLoading(false));
    };

    return <form onSubmit={e => e.preventDefault()}>
        <TextField placeholder="Name" value={location.name} onChange={name => setLocation({...location, ...{name}})} label="Location Name" errors={errors.name}/>

        <AddressSearch
            entity={location.address}
            setEntity={address => setLocation({...location, ...{address}})}
            label="Location Address"
            errors={errors.addressId}
            setErrors={addressId => setErrors({...errors, ...{addressId}})}/>

        <ButtonSelect
            label="Can anyone host events here?"
            value={location.public}
            options={[{value: 1, label: 'Yes'}, {value: 0, label: 'No'}]}
            setValue={val => setLocation({...location, ...{public: val}})}
            errors={errors.public}/>

        <div className='buttons'>
            <button className={cx('button is-link', {'is-loading': loading})} onClick={() => submitLocation()}>Add Location</button>
        </div>
    </form>;

};

export default NewLocation;