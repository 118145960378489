import React, {useState} from "react";
import {getUserFriends} from "../../../Helpers/Api/Player";
import Paginate from "../../../Components/Paginate/Paginate";
import Friend from "../../../Components/Friend/Friend";

const PlayerFriend = ({userId, setTabIndex}) => {

    const [friends, setFriends] = useState(null);
    const [search, setSearch] = useState('');

    if (friends === null)
        getUserFriends({id: userId})
            .then((list) => setFriends(list));

    const filteredFriends = friends ? friends.filter(
        game =>
            game.name.toLowerCase().includes(search.toLowerCase()) ||
            game.username.toLowerCase().includes(search.toLowerCase())
    ) : [];

    return (
        <div>
            <div className='columns is-mobile'>
                <div className='column subtitle'>Friends</div>
                <div className="column field is-2-desktop">
                    <p className="control has-icons-left">
                        <input className="input" name={search} type='text' placeholder='Search' value={search} onChange={e => setSearch(e.target.value)}/>
                        <span className="icon is-small is-left">
                      <i className="fa fa-search"/>
                    </span>
                    </p>
                </div>
            </div>
            <div>
                <Paginate elements={filteredFriends} perRow={5} perPage={50} Component={Friend}/>
            </div>
        </div>
    );
};

export default PlayerFriend;