import {apiUrl, AuthenticationHeaders, handleResponse} from "../Api";
import Auth from '../Authentication';

export const login = (loginDetails) =>
    fetch(`${apiUrl}/login`, AuthenticationHeaders('POST', loginDetails))
        .then(handleResponse)
        .then(user => {
            if (user) {
                Auth.isAuthenticated = true;
                Auth.user = user;
                localStorage.setItem('user', JSON.stringify(user));
            }
        });

export const logout = () => {
    if (localStorage.getItem('user')) {
        const request = fetch(`${apiUrl}/logout`, AuthenticationHeaders())
            .then(handleResponse);

        localStorage.removeItem('user');
        Auth.isAuthenticated = false;
        Auth.user = null;

        return request;
    }

    return new Promise(resolve => resolve());
};

export const register = (registrationDetails) =>
    fetch(`${apiUrl}/register`, AuthenticationHeaders('POST', registrationDetails))
        .then(handleResponse)
        .then(user => {
            if (user) {
                Auth.user = user;
                Auth.isAuthenticated = true;
                localStorage.setItem('user', JSON.stringify(user));
            }
        });