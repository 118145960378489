import React, {useEffect, useState} from "react";
import 'bulma-timeline/dist/css/bulma-timeline.min.css';
import Tabs from "../Components/Tabs/Tabs";
import PlayerAbout from "./Assets/Player/About";
import PlayerEvent from "./Assets/Player/Event";
import PlayerGame from "./Assets/Player/Game";
import {getUserDetails} from "../Helpers/Api/Player";
import Loading from "../Components/Loading/Loading";
import PlayerFriend from "./Assets/Player/Friend";

const PlayerPage = ({match}) => {

    const [tabIndex, setTabIndex] = useState(0);
    const [details, setDetails] = useState(null);
    const username = match.params.username;

    if (details === null)
        getUserDetails({username})
            .then((user) => setDetails(user));

    // If user browses to new profile update request
    useEffect(() => {
        setTabIndex(0);
        setDetails(null);
    }, [match]);

    const tabs = [
        {Page: PlayerAbout, title: 'About', props: {...details}},
        {Page: PlayerGame, title: 'Games'},
        {Page: PlayerFriend, title: 'Friends'},
        /*{Page: PlayerAbout, title: 'Plays'},
        {Page: PlayerAbout, title: 'Stats'},*/
        {Page: PlayerEvent, title: 'Events'},
    ];

    return (
        <div>
            <div className="title has-text-centered">{username}</div>
            {details === null && <Loading/>}
            {details &&
            <Tabs
                tabs={tabs}
                pageProps={{userId: details.user.id, setTabIndex}}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}/>}
        </div>
    );
};

export default PlayerPage;