import React, {useState, useEffect, useRef} from 'react';
import './Autocomplete.css';

const Autocomplete = ({value, setValue, searchCallback, selectCallback, name, options, label = null, delay = 0, placeholder = 'Search', resetTextPostSelect = false, errors = []}) => {

    const [open, setOpen] = useState(false);
    const [timer, setTimer] = useState(0);
    const hasOptions = Object.keys(options).length > 0;
    const autocomplete = useRef();

    const search = (newTerm) => {
        setValue(newTerm);
        clearTimeout(timer);
        setTimer(setTimeout(searchCallback, delay, newTerm));
    };

    const select = (option) => {
        setOpen(false);
        setValue(resetTextPostSelect ? '' : (option.text || option.label));
        selectCallback(option);
    };

    const handleClick = e => !autocomplete.current.contains(e.target) ? setOpen(false) : null;

    useEffect(() => { // used to detect outside click
        document.addEventListener("mousedown", handleClick);
        return () => document.removeEventListener("mousedown", handleClick);
    }, [autocomplete]);

    return (
        <div>
            {label && <label className="label">{label}</label>}
            <div className="field control has-addons" ref={autocomplete}>
                <div className="control is-expanded">
                    <input className="input" name={name} placeholder={placeholder} value={value} onFocus={() => setOpen(true)} onChange={e => search(e.target.value)}/>
                    {errors && <span className="help is-danger">{errors[0]}</span>}
                </div>
                <div className="control">
                    <div className="button">
                        <i className='fa fa-search'/>
                    </div>
                </div>
                {open && hasOptions && (<ul className="options-list">
                    {options.map(option => <li onClick={() => select(option)} key={option.value}>{option.label}</li>)}
                </ul>)}
            </div>
        </div>);
};

export default Autocomplete;