import React from 'react';
import {Route, Switch} from 'react-router-dom';
import styles from "./Assets/Routes/Styles.module.scss";
import cx from "classnames";
import LoginPage from "../Pages/Login";
import EventCreatePage from "../Pages/EventCreate";
import EventManagePage from "../Pages/EventJoin";
import HomePage from "../Pages/Home";
import RestrictedPage from "../Pages/Restricted";
import NoMatch404Page from "../Pages/NoMatch404";
import PrivateRoute from '../Components/Route/PrivateRoute';
import GuestRoute from '../Components/Route/GuestRoute';
import RegisterPage from "../Pages/Register";
import PlayerPage from "../Pages/Player";
import ProfileAvailabilityPage from "../Pages/ProfileAvailability";
import ProfileNotificationPage from "../Pages/ProfileNotification";
import ProfileGamePage from "../Pages/ProfileGame";

export default () => (
    <div className={styles.background}>
        <div className={cx("container", styles.container)}>
            <Switch>
                <Route path="/" component={HomePage} exact/>
                <GuestRoute path="/login" component={LoginPage}/>
                <GuestRoute path="/register" component={RegisterPage}/>
                <PrivateRoute path="/event/create" component={EventCreatePage}/>
                <PrivateRoute path="/event/:id" component={EventManagePage}/>
                <PrivateRoute path="/profile/availability" component={ProfileAvailabilityPage}/>
                <PrivateRoute path="/profile/notification" component={ProfileNotificationPage}/>
                <PrivateRoute path="/profile/game" component={ProfileGamePage}/>
                <PrivateRoute path="/player/:username?" component={PlayerPage} exact/>

                <Route path="/restricted" component={RestrictedPage}/>
                <Route path="*" component={NoMatch404Page}/>
            </Switch>
        </div>
    </div>
);