import React, {useState} from 'react';
import {listMyEvents, listPublicEvents} from '../Helpers/Api/Event';
import Map from "../Components/Map/OpenStreets";
import {Link, BrowserRouter} from "react-router-dom";
import Loading from "../Components/Loading/Loading";
import {getIpLocation} from "../Helpers/Api/ExternalApi/IpApi";
import Auth from "../Helpers/Authentication";

const HomePage = () => {

    const [locationError, setLocationError] = useState(false);
    const [events, setEvents] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [loading, setLoading] = useState(true);
    const hasEvents = events.length > 0;

    if (loading) {
        getIpLocation()
            .then(({latitude, longitude}) => {
                const eventCall = Auth.isAuthenticated ? listMyEvents({latitude, longitude}) : listPublicEvents({latitude, longitude});
                eventCall
                    .then(events => {
                        const markerPopup = (id) => <BrowserRouter><Link to={`/event/${id}`}>Link to event</Link></BrowserRouter>;
                        const newMarkers = events.map(event => ({popup: markerPopup(event.id), location: event.location}));
                        setMarkers(newMarkers);
                        setEvents(events);
                    });
            })
            .catch(() => setLocationError(true))
            .finally(() => setLoading(false));
    }

    return (<div className='columns'>
        {locationError && <div className='column'>we are unable to find your location</div>}
        <div className='column'>
            <p>Hi there meeple-o.</p>
            <p>Are you looking for new friends to play boardgames with? You are in the right place!</p>
            <p>You can join one of the existing events from the map, look for a new group or create your own event.</p>
            <div className='buttons'>
                <Link className={'button is-info'} to='/event/create'>Create Event</Link>
                <Link className={'button is-info'} to='/profile/availability'>Look for a group</Link>
            </div>

            {!hasEvents && !loading && <div>Unfortunately there are no events in your area.</div>}
            {hasEvents && events.map((event) => console.log(event))}
        </div>
        <div className='column'>
            {loading && !hasEvents && <Loading/>}
            {!loading && hasEvents && <Map locations={markers} zoom={10}/>}
        </div>
    </div>);
};

export default HomePage;