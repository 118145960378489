import {apiUrl, AuthenticationHeaders, handleResponse} from "../Api";

export const bggLogin = (details) =>
    fetch(`${apiUrl}/bgg/login`, AuthenticationHeaders('POST', details))
        .then(handleResponse);

export const bggHasSession = () =>
    fetch(`${apiUrl}/bgg/hasSession`, AuthenticationHeaders())
        .then(handleResponse);

export const bggUpdateCollection = () =>
    fetch(`${apiUrl}/bgg/updateCollection`, AuthenticationHeaders())
        .then(handleResponse);