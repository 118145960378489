import React, {Fragment, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Auth from '../Helpers/Authentication'
import cx from 'classnames';

const Header = () => {
    const [expand, setExpand] = useState(false);
    const history = useHistory();

    const logout = () => Auth.signOut().then(() => history.push("/"));

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link className="navbar-item" to="/">
                    <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28" alt="Ultimage Boardgamer Logo"/>
                </Link>

                <div role="button" className={"navbar-burger burger" + (expand ? ' is-active' : '')} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={() => setExpand(!expand)}>
                    <span aria-hidden="true"/>
                    <span aria-hidden="true"/>
                    <span aria-hidden="true"/>
                </div>
            </div>

            <div id="navbarBasicExample" className={cx("navbar-menu", {'is-active': expand})}>
                <div className="navbar-start">
                    <Link className="navbar-item" to="/">Play</Link>

                    <div className="navbar-item has-dropdown is-hoverable">
                        <div className="navbar-link">Settings</div>

                        <div className="navbar-dropdown">
                            <Link className="navbar-item" to="/profile/availability">Availability</Link>
                            <Link className="navbar-item" to="/profile/notification">Notifications</Link>
                            <Link className="navbar-item" to="/profile/game">Games</Link>
                            <Link className="navbar-item" to="/profile/notification">Locations</Link>
                        </div>
                    </div>
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            {!Auth.isAuthenticated && (
                                <Fragment>
                                    <Link className="button is-primary" to="/register">
                                        <strong>Sign up</strong>
                                    </Link>
                                    <Link className="button is-light" to="/login">
                                        Log in
                                    </Link>
                                </Fragment>)
                            }
                            {Auth.isAuthenticated && (
                                <button className="button is-light" onClick={() => logout()}>
                                    Logout
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </nav>);

};


export default Header;