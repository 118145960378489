import React, {useState} from 'react';
import {useHistory, Link} from "react-router-dom";
import Auth from "../Helpers/Authentication";
import TextField from "../Components/Form/TextField";
import cx from 'classnames';

const RegisterPage = () => {
    const history = useHistory();

    const [user, setUser] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const login = () => {
        setLoading(true);
        setErrors({});
        Auth.create(user)
            .then(() => history.replace({from: {pathname: "/"}}))
            .catch((errors) => setErrors(errors))
            .finally(() => setLoading(false));
    };

    return (
        <form onSubmit={e => e.preventDefault()}>
            <TextField
                placeholder="Your username"
                value={user.username}
                onChange={username => setUser({...user, ...{username}})}
                label="Username" errors={errors.username}/>

            <TextField
                placeholder="Your email"
                value={user.email}
                onChange={email => setUser({...user, ...{email}})}
                label="Email" errors={errors.email}/>

            <TextField
                placeholder="Your name"
                value={user.name}
                onChange={name => setUser({...user, ...{name}})}
                label="Name" errors={errors.name}/>

            <TextField
                type="password"
                placeholder="Your password"
                value={user.password}
                onChange={password => setUser({...user, ...{password}})}
                label="Password" errors={errors.password}/>

            <TextField
                type="password"
                placeholder="Your password"
                value={user.password_confirmation}
                onChange={password_confirmation => setUser({...user, ...{password_confirmation}})}
                label="Confirm Password"/>

            <div className="field is-grouped">
                <div className="control">
                    <button className={cx('button is-link', {'is-loading': loading})} onClick={() => login()}>Register</button>
                </div>
                <div className="control">
                    <Link to="/">
                        <button className="button is-text">Cancel</button>
                    </Link>
                </div>
            </div>
        </form>
    );
};

export default RegisterPage;