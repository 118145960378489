import React, {Fragment, useState} from 'react';
import {Link} from "react-router-dom";
import Game from "../../../Components/Game/Game";
import EventContext from "./Context";
import cx from 'classnames';
import {addEventGame, joinEventGame, leaveEventGame} from "../../../Helpers/Api/EventGame";
import AppContext from '../../../AppContext';
import GameSearch from "../../../Components/Autocomplete/GameSearch";

const EventGames = ({onNext, onBack}) => {

    const {event, setEvent} = React.useContext(EventContext);
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const tabs = ['Event Games', 'Bring a game', 'Games attendees want to play'];
    const statuses = ['Pending', 'Confirmed', 'Cancelled'];
    const [errors, setErrors] = useState([]);
    const [newGame, setNewGame] = useState(null);

    const {user} = React.useContext(AppContext);

    const hasSeats = game => game.attendees.length < game.playersMax;
    const isAttending = game => game.attendees.some(player => player.userId === user.userId);

    const addGame = (gameId, joinTable) => {
        setLoading(true);
        addEventGame({gameId, eventId: event.id, joinTable: !!joinTable})
            .then(game => {
                let {games} = {...event};
                games.push(game);
                setEvent({...event, ...{games}});
                setTab(0);
                setLoading(false);
            });
        setNewGame(null);
    };

    const joinGame = gameIndex => {
        setLoading(true);
        const eventGameId = event.games[gameIndex].id;
        joinEventGame({eventGameId})
            .then(() => {
                let {games} = {...event};
                games[gameIndex].attendees.push({userId: user.userId, status: 1, username: user.username});
                setEvent({...event, ...{games}});
                setLoading(false);
            })
    };

    const leaveGame = gameIndex => {
        if (window.confirm("Are you sure you wish to leave this game?")) {
            setLoading(true);
            const eventGameId = event.games[gameIndex].id;
            leaveEventGame({eventGameId})
                .then(({status}) => {
                    let {games} = {...event};
                    if (status === 'deleted-game')
                        games.splice(gameIndex, 1);
                    else
                        games[gameIndex].attendees = games[gameIndex].attendees.filter(attendee => attendee.userId !== user.userId);
                    setEvent({...event, ...{games}});
                    setLoading(false);
                })
        }
    };

    return (<div>
        <div className="tabs">
            <ul>
                {tabs.map((tabText, index) => <li className={cx({'is-active': index === tab})} key={index} onClick={() => setTab(index)}><a>{tabText}</a></li>)}
            </ul>
        </div>

        {tab === 0 && <div>You may attend one of the event games or suggest your own using the menu above</div>}
        {tab === 0 && !!event.games.length &&
        <div className="columns is-multiline">
            {event.games.map((game, gameIndex) => <Fragment key={game.gameId}>
                <div className="column is-one-quarter">
                    <Game {...game}/>
                </div>
                <div className="column is-one-quarter">
                    {game.start && <div className="label">Estimated starting time: {game.start}</div>}
                    <div className="label">Attendees</div>
                    {game.attendees.map((player, index) => <div key={index}><Link to={`/player/${player.username}`}>{player.username}</Link> - {statuses[player.status]}</div>)}
                    {game.playersMax && [...Array(game.playersMax - game.attendees.length)].map((val, index) => <div key={index}>--- Empty ---</div>)}
                    <div className="buttons">
                        {hasSeats(game) && !isAttending(game) && <button className={cx('button is-info', {'is-loading': loading})} onClick={() => joinGame(gameIndex)}>Join</button>}
                        {isAttending(game) && <button className={cx('button', {'is-loading': loading})} onClick={() => leaveGame(gameIndex)}>Cancel</button>}
                    </div>
                </div>
            </Fragment>)}
        </div>}

        {tab === 1 && <Fragment>
            <GameSearch
                entity={null}
                setEntity={option => setNewGame(option?.game)}
                label="Game"
                errors={errors}
                setErrors={games => setErrors(games)}/>
            {newGame &&
            <div className="columns">
                <div className="column is-one-quarter">
                    <Game {...newGame} />
                </div>
                <div className="column">
                    Please only add games that you are able to bring to the event. Select if you would like to join the play table.
                    <div className="buttons">
                        <button className="button is-info" onClick={() => addGame(newGame.gameId, true)}>Bring game & Join Table</button>
                        <button className="button" onClick={() => addGame(newGame.gameId)}>Bring game</button>
                    </div>
                </div>
            </div>}
        </Fragment>}

        {tab === 2 && !!event.gamesSuggested.length &&
        <div className="columns is-multiline">
            {event.gamesSuggested.map(game =>
                <div className="column is-one-quarter" key={game.gameId}>
                    <Game {...game}/>
                    <button
                        className={cx('button is-fullwidth', {'is-loading': loading})}
                        onClick={() => addGame(game.gameId, window.confirm("Would you like to join the game table?"))}>
                        Bring Game
                    </button>
                </div>)}
        </div>}

        <button className="button is-primary" onClick={() => onNext()}>Next</button>
        <button className="button is-text" onClick={() => onBack()}>Back</button>
    </div>);
};

export default EventGames;