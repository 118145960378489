import React, {useState} from "react";
import {getEvent} from "../Helpers/Api/Event";
import EventLocationsVote from "./Assets/EventJoin/LocationVote";
import EventMessages from "./Assets/EventJoin/Message";
import EventDetails from "./Assets/EventJoin/Detail";
import EventGames from "./Assets/EventJoin/Game";
import EventContext from './Assets/EventJoin/Context';
import Wizard from "../Components/Wizard/Wizard";

const EventManagePage = ({match}) => {

    const [event, setEvent] = useState();

    event === undefined && getEvent(match.params.id).then(data => setEvent(data));

    const steps = event?.locationId ? [
        {Page: EventDetails, title: 'Details'},
        {Page: EventGames, title: 'Games'},
        {Page: EventMessages, title: 'Chat'},
    ] : [
        {Page: EventDetails, title: 'Details'},
        {Page: EventLocationsVote, title: 'Location'},
        {Page: EventGames, title: 'Games'},
        {Page: EventMessages, title: 'Chat'},
    ];

    return <EventContext.Provider value={{event, setEvent}}>
        {event && <Wizard steps={steps} title={`Event #${event.id}`} freeBrowse={true}/>}
    </EventContext.Provider>;
};

export default EventManagePage;