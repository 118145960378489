import React, {useState} from 'react';
import {addEventLocation, voteEventLocation} from "../../../Helpers/Api/Event";
import Map from "../../../Components/Map/OpenStreets";
import cx from "classnames";
import EventContext from "./Context";
import styles from './Style/LocationVote.module.css';
import AddLocation from '../../../Components/Location/AddLocation';

const EventLocationsVote = ({onNext, onBack}) => {

    const {event, setEvent} = React.useContext(EventContext);
    const [activeMarker, setActiveMarker] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [suggestLocation, setSuggestLocation] = useState(false);

    const prepareMarkers = () => {
        const markerPopup = (id, name, address) => <div style={{padding: '10px', fontSize: '15px'}}><b>Suggested Location</b>
            <div>Name: {name}</div>
            <div>Address: {address}</div>
        </div>;
        const overlapPopup = <div style={{padding: '10px', fontSize: '15px'}}>This is the common play area.</div>;
        let newMarkers = event.suggestedLocations.map((loc) => ({popup: markerPopup(loc.id, loc.name, loc.address), location: loc.location}));

        if (event.location)
            newMarkers.push({
                popup: event.location.name ? markerPopup(event.location.id, event.location.name, event.location.address) : overlapPopup,
                location: event.location.location
            });
        setMarkers(newMarkers);
    };

    const vote = (locationId, vote, index) =>
        voteEventLocation({locationId, vote})
            .then(() => {
                let {suggestedLocations} = {...event};
                suggestedLocations[index].votes += vote - (suggestedLocations[index].vote || 0);
                suggestedLocations[index].vote = vote;
                setEvent({...event, ...{suggestedLocations}});
            });

    const addLocation = loc => {
        if (loc) {
            addEventLocation({eventId: event.id, locationId: loc.id})
                .then(() => {
                    let {suggestedLocations} = {...event};
                    loc.vote = 1;
                    loc.votes = 1;
                    suggestedLocations.push(loc);
                    setEvent({...event, ...{suggestedLocations}});
                    prepareMarkers();
                });
        }
        setSuggestLocation(false);
    };

    if (markers.length === 0 && event.suggestedLocations?.length)
        prepareMarkers();

    return (
        <div>
            <div className='subtitle'>Event Location</div>
            <div>This event was organized by system matchmaking. A location must be chosen by all event attendees. <br />Please vote for your favourite venue or suggest new ones.</div>
            <div className="columns">
                <div className="column">
                    <Map locations={markers} activeMarker={activeMarker} markerOpacity={0.3} pad={0}/>
                </div>

                {!suggestLocation &&
                <div className="column">
                    {!event.locationId && event.suggestedLocations?.map((location, index) =>
                        <div key={index} onMouseOver={() => setActiveMarker(index)} onMouseOut={() => setActiveMarker(null)} className={cx({[styles.isHovered]: activeMarker === index})}>
                            {location.name} @ {location.votes} @
                            <button className={cx('button is-link', {'is-outlined': location.vote !== 1})} onClick={() => vote(location.id, location.vote === 1 ? 0 : 1, index)}>Up</button>
                            <button className={cx('button is-link', {'is-outlined': location.vote !== -1})} onClick={() => vote(location.id, location.vote === -1 ? 0 : -1, index)}>Down</button>
                        </div>
                    )}
                    {!markers.length && <div>There are no available locations for this event. Please suggest one using the form below.</div>}
                    {<button className="button" onClick={() => setSuggestLocation(true)}>Suggest Location</button>}
                </div>}
                {suggestLocation &&
                <div className="column">
                    <AddLocation callBack={addLocation} cancel={true}/>
                </div>}
            </div>

            <button className="button is-primary" onClick={() => onNext()}>Next</button>
            <button className="button is-text" onClick={() => onBack()}>Back</button>
        </div>
    );
};

export default EventLocationsVote;