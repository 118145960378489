import {apiUrl, handleResponse, AuthenticationHeaders} from "../Api";

export const addLocation = (location) =>
    fetch(`${apiUrl}/location/add`, AuthenticationHeaders('POST', location))
        .then(handleResponse);

export const listUserLocation = () =>
    fetch(`${apiUrl}/location/user/list`, AuthenticationHeaders())
        .then(handleResponse);

export const listPublicLocation = (address) =>
    fetch(`${apiUrl}/location/public/list`, AuthenticationHeaders('POST', address))
        .then(handleResponse);